import { DashboardPopupMenu } from 'components/dashboard-popup-menu/component'
import { Notifications, showNotificationArea } from 'components/notifications/component'
import 'shared/notifications/_styles.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import * as Router from 'router'

import { Banner } from 'components/banner/component'
import { CommunityDropdown } from 'components/community-dropdown/component'
import { CarrotDownBlack } from 'components/icons/carrot-down-black'
import { RasaLogo } from 'components/icons/rasalogo'
import { Notification } from 'components/notification/component'
import { RasaContext } from 'context'
import { Stats, StatsType } from 'stats'
import { Roles } from '../../shared/constants'
import { MenuComponent } from '../tab-menu/component'
import './_styles.scss'
import { DashboardMenuOption } from './constants'
import { AccountUsageStats } from './usage-stats'
import * as GlobalConstants from '../../constants'
export { DashboardMenuOption } from './constants'
interface DashboardMenuProps {
  notifications: any,
  flash: any,
  push: any,
  selected: DashboardMenuOption,
  menuComponent?: MenuComponent,
  app?: any
}

interface DashboardMenuState {
  accountExpired: boolean,
  communityIdentifier: string,
  email: string,
  firstName: string,
  logoUrl: string,
  showPopup: boolean,
}

class DashboardMenuComponent extends Component<DashboardMenuProps, DashboardMenuState> {
  public static contextType = RasaContext;
  private _lastSelected: DashboardMenuOption = DashboardMenuOption.dashboard
  private _stats: Stats

  constructor(props: DashboardMenuProps) {
    super(props);
    this._stats = Stats.init()
    if (this._stats.getStat(StatsType.MENU_LOAD)) {
      this._stats.start(StatsType.MENU_RELOAD)
    } else {
      this._stats.start(StatsType.MENU_LOAD)
    }

    this.state = {
      accountExpired: false,
      communityIdentifier: '',
      email: '',
      firstName: '',
      logoUrl: '',
      showPopup: false,
    };
    this._lastSelected = props.selected
  }

  public componentDidMount() {
    this.context.user.init()
    .then(({ person, activeCommunity }) => {
      this.setState({
        communityIdentifier: activeCommunity.communityId,
        accountExpired: person.accountExpired(),
        email: this.context.user.person.email,
        firstName: this.context.user.person.firstName,
        logoUrl: this.context.user.activeCommunity.data.whitelabel_logo_url,
      }, () => {
        if (this._stats.getStat(StatsType.MENU_LOAD)) {
          this._stats.stopAndLog(StatsType.MENU_RELOAD)
        } else {
          this._stats.stopAndLog(StatsType.MENU_LOAD)
        }
      })
    })
  }

  public render() {
    const { activeCommunity, person } = this.context.user
    const isAccountSuspendedClass = this.context.user.activeCommunity &&
    this.context.user.activeCommunity.data &&
    this.context.user.activeCommunity.data.community_account_suspended === 1
    && activeCommunity.role !== Roles.super_admin ? 'account-suspended' : ''

    if (activeCommunity && activeCommunity.communityInfo && person && person.accountInfo) {
      return (
        <div>
          <div id="dashboard-menu-container"
               data-rasa-account={(person.accountInfo || {}).account_guid}
               data-rasa-community={activeCommunity.communityId}>
            <Banner/>
            <div className="dashboard-menu">
              <div className={`dashboard-menu-item dashboard-rasa-logo ${isAccountSuspendedClass}`}
                  onClick={() => this.handleClick(DashboardMenuOption.dashboard)}>
                {this.state.logoUrl ? <img src={this.state.logoUrl}/> : <RasaLogo/> }
              </div>
              <this.Navigation item={DashboardMenuOption.content} name="Content"
                disabledClass = {isAccountSuspendedClass}/>
              <this.Navigation item={DashboardMenuOption.emailDesign} name="Design"
                disabledClass = {isAccountSuspendedClass}/>
              <this.Navigation item={DashboardMenuOption.preview} name="Preview"
                disabledClass = {isAccountSuspendedClass}/>
              <this.Navigation item={DashboardMenuOption.contacts} name="Contacts"
                disabledClass = {isAccountSuspendedClass}/>
              {!this.state.accountExpired &&
              <this.Navigation item={DashboardMenuOption.schedule} name="Schedule"
                disabledClass = {isAccountSuspendedClass}/>}
              <this.Navigation item={DashboardMenuOption.analytics} name="Analytics"
                disabledClass = {isAccountSuspendedClass}/>
              <this.Navigation item={DashboardMenuOption.widgets} name="Tools"
                               disabledClass = {isAccountSuspendedClass}/>
              <this.Navigation item={DashboardMenuOption.settings} name="Settings"
                               disabledClass = {isAccountSuspendedClass}/>
              <div className="usage-widget-section">
              <AccountUsageStats menuComponent={this.props.menuComponent}/>
              </div>
              <div className="dashboard-company-logo-and-popup-container">
                <div className={"help-container"}>
                  <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank"><i className="fa fa-question-circle help-icon" aria-hidden="true"></i></a>
                </div>
                <div className={`dashboard-company-logo-and-hamburger-container ${isAccountSuspendedClass}`}>
                  <div className="hamburger-container">
                    <Notification />
                  </div>
                  <div className="hamburger-container">
                    <CommunityDropdown />
                  </div>
                </div>
                <Button className="dashboard-menu-popup-button-container dashboard-menu-item clickable-item"
                    disabled={!person.email}
                    onClick={() => {this.setState({...this.state, showPopup: !this.state.showPopup})}}>
                  {(person.firstName || person.email)  &&
                  <div className="user-greetings">Hi, {person.firstName || person.email} &nbsp; &nbsp;</div>}
                  <CarrotDownBlack />
                  <img src="" alt=""></img>
                  <img src="" alt=""></img>
                </Button>
              </div>
            </div>
            {this.state.showPopup ?
              <div className="dashboard-menu-popup-container">
                <DashboardPopupMenu onChange={() => this.setState({...this.state, showPopup: false})}
                                    onClose={() => this.setState({...this.state, showPopup: false})}>
                </DashboardPopupMenu>
              </div> : null}
            <div className="clearfix"></div>
            {(showNotificationArea(this.props.notifications) || this.props.flash.message) && <Notifications />}
          </div>
        </div>
      )
    } else {
        return null
    }
  }

  private Navigation = ({item, name, disabledClass}: any) => {
    return <div className={this.getMenuItemStyle(item, disabledClass)} onClick={() => this.handleClick(item)}>
      {name}
    </div>
  }

  private getMenuItemStyle(item: DashboardMenuOption, disabledClass: string) {
    if (this._lastSelected === item) {
      return `dashboard-menu-item dashboard-menu-selected clickable-item ${disabledClass}`
    } else {
      return `dashboard-menu-item clickable-item ${disabledClass}`
    }
  }

  private handleClick(item: DashboardMenuOption) {
    this.props.push(item)
  }
}

export const DashboardMenu = connect(
  ({notifications, flash, app}: any) => ({notifications, flash, app}),
  {
    push: Router.push,
  },
)(DashboardMenuComponent)
